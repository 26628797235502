import React from "react";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { MoreVertical } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Button } from "./ui/button";

interface RecordedFilesCardProps {
  audioUrl: string | null;
  audioBlob?: Blob | null;
}

const RecordedFilesCard: React.FC<RecordedFilesCardProps> = ({
  audioUrl,
  audioBlob,
}) => {
  const handleDownload = () => {
    if (audioBlob) {
      const url = window.URL.createObjectURL(audioBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `recording-${new Date().toLocaleDateString()}.ogg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Card className="glass-effect text-white">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Recorded File</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        {audioUrl && (
          <div className="flex items-center gap-2">
            <audio
              controls
              preload="auto"
              controlsList="nodownload"
              style={{ width: "100%" }}
            >
              <source src={audioUrl} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
            {audioBlob && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    onClick={handleDownload}
                    className="cursor-pointer"
                  >
                    Download
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default RecordedFilesCard;
